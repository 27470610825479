
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/accordion/Overview.vue";
import BasicExample from "@/view/pages/resources/documentation/base/accordion/BasicExample.vue";
import AnimatedIcon from "@/view/pages/resources/documentation/base/accordion/AnimatedIcon.vue";

export default defineComponent({
  name: "accordion",
  components: {
    Overview,
    BasicExample,
    AnimatedIcon,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Accordion");
    });
  },
});
